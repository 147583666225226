import React, { useState } from "react"
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ReactMarkdown  from "react-markdown"
import rehypeRaw from 'rehype-raw'
import BreadCrumb from '../components/breadcrumb'

const FormatedText = ({title, content, allowHTML, readerMenu, pageViews, chapterTitle, breadcrumbs}) => {
    const [fontSize, setFontSize] = useState('normal')
    const [fontType, setFontType] = useState('sans')
    const [justifyParagraph, setJustifyParagraph] = useState('justify')

    const changeFontSize = () =>{
        switch(fontSize) {
            case "normal":
                setFontSize("large")
            break
            case "large":
                setFontSize("x-large")
            break
            case "x-large":
                setFontSize("normal")
            break
            default:
                setFontSize("normal")
        }
    }
    const changeJustifyParagraph = () =>{
        switch(justifyParagraph) {
            case "auto":
                setJustifyParagraph("justify")
            break
            case "justify":
                setJustifyParagraph("auto")
            break
            default:
                setJustifyParagraph("justify")
        }
    }
    const changeFontType = () =>{
        switch(fontType) {
            case "serif":
                setFontType("sans")
            break
            case "sans":
                setFontType("serif")
            break
            default:
                setFontSize("sans")
        }
    }

    const readingTimeElements = <span><i class="far fa-clock"></i> {Math.ceil(content.split(" ").length / 300) + " min.     "}</span>
    const viewsCountElements = pageViews > 0 ? <span><i class="far fa-eye"></i> {pageViews}</span>: <></>
    const readerMenuElements = readerMenu ?<div id="reader-menu">
            <ButtonGroup aria-label="TextStyle">
            <Button variant="primary"
            onClick={changeFontSize}>
                <i class="fas fa-text-height"></i>
            </Button>
            {
                (justifyParagraph === "justify"?
                    <Button variant="primary"
                    onClick={changeJustifyParagraph}>
                        <i class="fas fa-align-left"></i>
                    </Button>
                :
                    <Button variant="primary"
                    onClick={changeJustifyParagraph}>
                        <i class="fas fa-align-justify"></i>
                    </Button>
                )
            }
            <Button variant="primary"
                onClick={changeFontType}>
                    <i class="fas fa-font"></i>
            </Button>
            </ButtonGroup>
            {`            `}
            {readingTimeElements}
            {viewsCountElements}
        </div> : <></>
    return (
        <>
        {readerMenuElements}
        
        {(title ? <h1>{title}</h1>:<></>)}
        {(breadcrumbs ? <BreadCrumb itemArray = {breadcrumbs}/>:<></>)}
        {(chapterTitle ? <h2>{chapterTitle}</h2>:<></>)}
        <article font-size={fontSize} justify-paragraph={justifyParagraph} font-type={fontType}>
        <div className="max-w-2xl mx-auto">
        {(allowHTML ? <ReactMarkdown  rehypePlugins={[rehypeRaw]} children={content} />: <ReactMarkdown  children={content} />)}
        </div>
        </article>
        </>
    )
}

export default FormatedText
