

import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "gatsby"

const BreadCrumb = ({itemArray}) => {
    console.log(itemArray)
    return (
    <Breadcrumb>
    {
    itemArray?.map((item,index)=>{
        if (index === itemArray.length -1 ){
            return (<><Breadcrumb.Item active>{item.text}</Breadcrumb.Item></>)
        }else{
            return (<><Breadcrumb.Item><Link to={item.url}>{item.text}</Link></Breadcrumb.Item></>)
        }
    }
    )
    }
    </Breadcrumb>
    )

    

}

export default BreadCrumb
